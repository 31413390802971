import CartActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "Cart";

function allocateCart(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/allocate`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );
      const {
        message: { record },
      } = response || {};
      dispatch({
        type: CartActionTypes.ALLOCATE_CART,
        data: record,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getOne(token, id) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      const {
        message: { record },
      } = response || {};
      dispatch({
        type: CartActionTypes.GET_ONE,
        data: record,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function resetCart() {
  return async (dispatch) => {
    dispatch({
      type: CartActionTypes.RESET_CART,
    });
  };
}

function checkout(token, data, instant) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/checkout`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      const {
        message: { cart },
      } = response || {};
      if (instant) {
        dispatch({
          type: CartActionTypes.CHECKOUT,
          data: {},
        });
      } else {
        dispatch({
          type: CartActionTypes.CHECKOUT,
          data: cart,
        });
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function mergeCart(token, data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/merge`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      const {
        message: { cart },
      } = response || {};
      dispatch({
        type: CartActionTypes.MERGE_CART,
        data: cart,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function addToCart(token, cartID, productID, quantity, cartItems) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/${cartID}`,
      null,
      token
    );
    try {
      const selectedItem = cartItems.items.find(
        (item) => item.product._id === productID
      );
      let data = [...cartItems.items];
      if (selectedItem) {
        data = data.map((item) => {
          if (["virtual"].indexOf(item.product.type) !== -1) {
            return {
              product: item.product._id,
              qty: item.qty,
              orderAttribute: item.orderAttribute,
            };
          }
          if (item.product._id === productID) {
            return {
              product: productID,
              qty: selectedItem.qty + quantity,
            };
          }
          return { product: item.product._id, qty: item.qty };
        });
      } else {
        data.push({ product: productID, qty: quantity });
      }
      const response = await APIHelper(
        "PUT",
        url.toString(),
        { items: data },
        true,
        headers
      );
      const {
        message: { cart },
      } = response || {};
      dispatch({
        type: CartActionTypes.MERGE_CART,
        data: cart,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const CartActions = {
  ...BaseActions(RESOURCE, CartActionTypes),
  allocateCart,
  getOne,
  resetCart,
  checkout,
  mergeCart,
  addToCart,
};
export default CartActions;
