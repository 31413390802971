export default {
  loading: "読み込み中",
  homepage: "ホームページ",
  search: "探す",
  default: "デフォルト",
  save: "保存する",
  noRecord: "記録なし",
  add: "追加",
  date: "日付",
  delete: "削除",
  quit: "終了する",
  navMenu: {
    login: "サインイン",
    register: "登録",
    myAccount: "マイアカウント",
    logout: "ログアウト",
    quantity: "量",
    sku: "モデル",
    option: "オプション",
    total: "合計",
    viewCart: "かごの中身を見る",
    checkout: "チェックアウト",
    noItemInCart: "カートにアイテムがありません",
    aboutUs: "私たちに関しては",
    redemption: "Customer Redemption",
    balance: "Coins Balance: {{coins}}",
    sessionExpired: "Your session has expired. Please login again.",
  },
  footer: {
    aboutus: "私たちに関しては",
    contact: "contact",
    usefulLinks: "便利なリンク",
    disclaimer: "免責事項",
    policy: "プライバシーポリシー",
    tnc: "Terms and Conditions",
    guide: "Shopping Guide",
    subscribeUs: "購読する",
    Facebook: "Facebook",
    Youtube: "Youtube",
    Instagram: "Instagram",
  },
  home: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    buyNow: "今買う",
    latestProducts: "新着",
    latestProductsSubTitle: "毎月最新の製品を提供します",
    new: "新着",
    selectOptions: "オプションを選択",
    addToCart: "カートに追加",
    addedToCart: "カートに追加",
    outOfStock: "在庫切れ",
    view: "見る",
    addedToWishlist: "ウィッシュリストに追加",
    addToWishlist: "ほしい物リストに追加する",
    blogTitle: "How's our KOL say?",
    writtenBy: "Written by",
    featureTitle1: "2 FREE SAMPLES",
    featureSubTitle1: "With Every Order",
    featureTitle2: "FREE NEXT DAY DELIVERY",
    featureSubTitle2: "Order by 12 pm. HK Only.",
    featureTitle3: "FREE GIFT WRAP",
    featureSubTitle3: "Select At Checkout",
    bannerTitle1: "",
    bannerTitle2: "",
    viewMoreProducts: "他の製品を見る",
  },
  productList: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    allProducts: "すべての製品",
    priceLowToHigh: "価格-低から高",
    priceHighToLow: "価格-高から低",
    updateTimeNewToOld: "更新時間-新旧",
    updateTimeOldToNew: "更新時間-古いものから新しいものへ",
    categories: "カテゴリ",
    noCategories: "すべてのカテゴリ",
    buyNow: "今買う",
    new: "新着",
    selectOptions: "オプションを選択",
    addToCart: "カートに追加",
    addedToCart: "カートに追加",
    outOfStock: "在庫切れ",
    view: "見る",
    addedToWishlist: "ウィッシュリストに追加",
    addToWishlist: "ほしい物リストに追加する",
    addedToCompare: "比較に追加",
    addToCompare: "比較リストに追加",
    brands: "Brands",
    noBrands: "All Brands",
    point: "points",
  },
  productDetails: {
    metaTitle: "Kingsmart | Product Detail",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    productDetails: "製品詳細",
    sku: "モデル",
    option: "オプション",
    buyNow: "今買う",
    addToCart: "カートに追加",
    outOfStock: "在庫切れ",
    additionalInfo: "追加情報",
    alsoBuy: "You may also like",
    quantity: "量",
    packageTotal: "パッケージ合計",
    relatedProducts: "あなたもこれが好きかもしれません",
    relatedCategories: "Related Categories",
    alsoLike: "You may also like",
    categories: "Categories",
    packageDetails: "Package Details",
    preferredDate: "Preferred Date",
    preferredTime: "Preferred Time",
    remark: "Remark",
    select: "Select",
    simplrStart: "Start Time",
    simplrEnd: "End Time",
    simplrInvoice: "Booking Reference",
    point: "points",
  },
  loginRegister: {
    metaTitle: "Kingsmart | サインイン / 登録",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    loginRegister: "サインイン｜登録",
    login: "サインイン",
    register: "登録",
    email: "電子メールアドレス",
    password: "パスワード",
    rememberMe: "私を覚えてますか",
    forgotPassword: "パスワードを忘れる",
    firstName: "名前",
    lastName: "氏",
    phone: "携帯電話番号",
    loginPanelTitle: "すでにメンバーですか？",
    registerPanelTitle: "今日ご参加ください！",
    registerPanelSubTitle:
      "メンバーになるために登録してください。 私たちの最新ニュースをお楽しみに！",
    registerModalTitle: "登録",
    resetPasswordMsg:
      "We will send an email to your email address for reset password if your record is found in our system.",
    validateAccountMsg: "Email validation success.",
    referralCode: "Referral Code",
  },
  myAccount: {
    metaTitle: "Kingsmart | My Account",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    myAccount: "マイアカウント",
    personalInfo: "口座情報",
    deliveryAddress: "発送先住所",
    orderRecord: "注文記録",
    personalInfoTitle: "あなたの個人情報",
    firstName: "名前",
    lastName: "氏",
    phone: "携帯電話番号",
    email: "電子メールアドレス",
    address: "住所",
    changePassword: "パスワードを変更する",
    newPassword: "新しいパスワード",
    confirmPassword: "パスワードを認証する",
    addNewAddress: "新しい出荷先住所",
    addressLine1: "住所1",
    addressLine2: "住所2",
    addressLine3: "市",
    stateProvince: "州/県",
    zipCode: "郵便番号",
    countryRegion: "国/地域",
    order: "注文",
    orderId: "注文ID",
    orderStatus: "注文の状況",
    paymentStatus: "支払い状況",
    deliveryStatus: "配送状況",
    total: "合計",
    details: "詳細",
    receiverName: "受信名",
    contactPhone: "連絡先番号",
    image: "画像",
    productName: "商品名",
    unitPrice: "単価",
    quantity: "量",
    subTotal: "小計",
    sku: "モデル",
    membership: "メンバーシップ",
    coupons: "クーポン",
    option: "オプション",
    membershipTier: "Membership Tier",
    amountSpent: "You have spent {{amount}} within 12 months",
    credit: "Credit",
    totalCredit: "Total Credit",
    expiryDate: "Expiry Date",
    dollars: "{{amount}} Coins",
    date: "Date",
    event: "Event",
    silver: "Silver",
    gold: "Gold",
    discountName: "Discount Name",
    discountAmount: "Discount Amount",
    productDetails: "Product Details",
    discountDetails: "Discount Details",
    shippingFee: "Shipping Fee",
    preferredDate: "Booking Date",
    preferredTime: "Booking Time",
    remark: "Remark",
    status: "Status",
    redemption: "Redemption",
    noResults: "No Results",
    adjust: "Adjust",
    pay: "Pay",
    topup: "Top-up",
    rebate: "Rebate",
    reward: "Reward",
    totalPoint: "Total Point",
    points: "Points",
    welcome: "Welcome",
    firstPurchase: "First Purchase",
    dailyLogin: "Daily Login",
    referral: "Referral",
    convert: "Convert",
    convertToKcoin: "Convert to Coins",
    youHave: "You have",
    exchangeTo: "You can exchange to",
    purchasedContent: "View Purchased Content",
  },
  cart: {
    metaTitle: "Kingsmart | Cart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    cart: "カート",
    yourCart: "あなたのカート",
    image: "画像",
    productName: "商品名",
    unitPrice: "単価",
    quantity: "量",
    subTotal: "小計",
    sku: "モデル",
    option: "オプション",
    continueShopping: "ショッピングを続ける",
    clearCart: "クリアカート",
    useCoupon: "クーポンコードを使用する",
    inputCouponCode: "ここでクーポンコードを選択/入力してください",
    applyCouponCode: "クーポンコードを適用する",
    cartTotal: "カート合計",
    totalDiscount: "合計割引",
    total: "総計",
    continueCheckout: "チェックアウトに進む",
    noItemInCart: "カートにアイテムがありません",
    buyNow: "今買う",
    noStock: "Item out of stock",
    linkAccount: "Link up Customer's Account",
    checkout: "Checkout Now",
    disabledItems: "Invalid Items",
    removeDisabled: "(Please remove the below items before checkout)",
    removeAllDisabled: "Remove all invalid items from cart",
  },
  checkout: {
    metaTitle: "Kingsmart | Checkout",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    checkout: "チェックアウト",
    billingDetails: "Billing Details",
    firstName: "名前",
    lastName: "氏",
    phone: "携帯電話番号",
    email: "電子メールアドレス",
    companyName: "名前",
    addressLine1: "住所1",
    addressLine2: "住所2",
    addressLine3: "市",
    stateProvince: "州/県",
    zipCode: "郵便番号",
    countryRegion: "国/地域",
    additionalInfo: "追加情報",
    otherRemarks: "その他の備考",
    yourOrder: "ご注文",
    product: "製品",
    total: "Amount Payable",
    delivery: "配達",
    discount: "ディスカウント",
    totalDiscount: "合計割引",
    freeShipping: "送料無料",
    submitOrder: "注文する",
    noItemInCart: "カートにアイテムがありません",
    buyNow: "今買う",
    confirmBooking:
      "Staff will confirm your booking very soon. Updates will be sent to your mailbox.",
    insufficientCoin:
      'There is insufficient K coins in your account. If you wish to checkout by credit card, please click "Yes".',
    staffCode: "Input Staff Code",
    apply: "Apply",
    tokenError: "QR Code has been expired or revoked.",
    required: "Required",
    optional: "Optional",
    redemption: "Redeem Credits",
    remove: "Remove",
    failedToCheckout:
      "Unable to checkout this order. Please review your cart items.",
    wrongStaffCode: "Please input correct staff code",
    insufficientPoint: "Insufficient Point",
    district: "District",
    area: "Area",
    locker: "Pick Up Point",
    lockerReminder:
      "If total weight of the products is >5kg, we will contact you separately to arrange delivery",
    lockerAddress: "Address",
    lockerOpenHours: "Open Hours",
    flat: "Flat/Room",
    floor: "Floor",
    block: "Block",
    building: "Building",
    street: "Street",
  },
  paymentNotification: {
    metaTitle: "Kingsmart | Payment Result",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    paymentNotification: "支払い通知",
    backToHome: "家に帰る",
    orderSuccessTitle: "注文の成功",
    orderSuccessMsg:
      "ご購入いただきありがとうございます！ ご注文を承りました。詳細についてはメールをご確認ください。",
    orderCancelledTitle: "注文はキャンセルされました",
    orderCancelledMsg:
      "ご注文はキャンセルされる場合があります。 詳細については、カスタマーサービスにお問い合わせください。",
    orderPendingTitle: "注文保留中",
    orderPendingMsg:
      "ご購入いただきありがとうございます！ ご注文を承りました。詳細についてはメールをご確認ください。",
  },
  aboutUs: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    aboutUs: "私たちに関しては",
  },
  blog: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    relatedProducts: "関連製品",
    relatedCategories: "Related Categories",
    blogPost: "ブログ投稿",
  },
  wishlist: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    wishlist: "ウィッシュリスト",
    image: "画像",
    productName: "商品名",
    unitPrice: "単価",
    selectOptions: "オプションを選択",
    addToCart: "カートに追加",
    addedToCart: "カートに追加",
    outOfStock: "在庫切れ",
    continueShopping: "ショッピングを続ける",
  },
  compare: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    compare: "Compare",
    product: "Product",
    unitPrice: "Unit Price",
    description: "Description",
    sku: "Model",
    option: "Option",
    additionalInfo: "Additional information",
    view: "View",
    continueShopping: "Continue Shopping",
  },
  resetPassword: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    resetPassword: "Reset Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
  },
  alerts: {
    accountNotFound: "Account not found",
    wrongEmail: "Please provide correct email",
    wrongConfirmPassword: "New and confirm password are different",
    wrongPasswordFormat:
      "Please fill in correct password.\n" +
      "Password consists of at least 8 characters, which must include number and letters (at least one capital letter)",
    wrongPhoneFormat: "Please provide correct mobile",
    fillNecessaryInfo: "Please fill in all necessary information",
    updateAccountInfoSuccess: "Information has been updated",
    updatePasswordSuccess: "Password has been updated",
    referencePriceRemarks: "Reference price",
  },
  qrCodeScanner: {
    scan: "Scan QR Code",
    enableAccess: "Please Enable Camera Access",
    or: "or",
    inputMobile: "Input customer's mobile",
    submit: "Submit",
  },
  customerRedemption: {
    metaTitle: "Kingsmart | Customer Redemption",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "Booking Items",
    image: "Image",
    productDetails: "Product Details",
    unitPrice: "Unit Price",
    staffCode: "Staff Code",
    redeem: "Redeem",
    invalidCode: "Invalid QR Code",
    enterMobile: "Please enter customer mobile",
    redeemed: "Redeemed",
    enterCode: "Please enter staff code",
    redeemedAt: "Redemption Date",
    expiredAt: "Expiry Date",
    cancelledAt: "Cancellation Date",
    available: "Available",
    expired: "Expired",
    cancelled: "Cancelled",
    noResults: "No Results",
  },
  bookingConfirmation: {
    metaTitle: "Kingsmart | Booking Confirmation",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "Booking Items",
    image: "Image",
    productDetails: "Product Details",
    unitPrice: "Unit Price",
    staffCode: "Staff Code",
    redeem: "Redeem",
    redeemed: "Redeemed",
    enterCode: "Please enter staff code",
    preferredDate: "Preferred Date",
    preferredTime: "Preferred Time",
    remark: "Remark",
    submit: "Submit",
    book_success: "Service has been updated",
    pending: "Pending",
    revoked: "Revoked",
    expired: "Expired",
  },
  coupon: {
    active: "Active",
    event: "Event",
    code: "Code",
    purchase_reached: "Purchase Reached",
    expiry_date: "Expiry Date",
    redeemed_expired: "Redeemed / Expired",
  },
  simplr: {
    unavailable: "Period unavailable. Please try another timeslot",
  },
};
