import React from "react";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { load, save } from "redux-localstorage-simple";
import reducer from "./reducers";
// import {composeWithDevTools} from "redux-devtools-extension";

const loggerMiddleware = createLogger({});

const enhancer = compose(
  applyMiddleware(
    thunk, // lets us dispatch() functions
    // loggerMiddleware,
    save({ namespace: "PINNBUY_V2" })
    // save()
  )
);

export default function configureStore() {
  return createStore(reducer, load({ namespace: "PINNBUY_V2" }), enhancer);
}
