import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Product";

const ProductActionTypes = {
  RESOURCE,
  ...BaseActionTypes(RESOURCE),
  GET_BY_CATEGORY: `${RESOURCE}/GET_BY_CATEGORY`,
  GET_STOCK: `${RESOURCE}/GET_STOCK`,
};

export default ProductActionTypes;
