import RewardActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "Reward";

function getPlans(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/plans`,
      null,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: RewardActionTypes.GET_PLAN,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function convertCoins(token, id, plan) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/${id}/reward/convert`,
      null,
      token
    );

    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        plan,
        true,
        headers
      );
      console.log("response", response);
      dispatch({
        type: RewardActionTypes.CONVERT_COINS,
      });

      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);

      return Promise.reject(error);
    }
  };
}

const RewardActions = {
  ...BaseActions(RESOURCE, RewardActionTypes),
  getPlans,
  convertCoins,
};
export default RewardActions;
