import Immutable from "seamless-immutable";

const initialState = Immutable([]);

const ShippingMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default ShippingMethodReducer;
