import BlogActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "blog";

function fetchPosts(id, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/${id}`,
      null,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      dispatch({
        type: BlogActionTypes.FETCH_POSTS,
        payload: response.data,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

const BlogActions = {
  ...BaseActions("blog", BlogActionTypes),
  fetchPosts
};
export default BlogActions;
