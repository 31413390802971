import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "me";

const RewardActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_PLAN: `${RESOURCE}/GET_PLAN`,
  CONVERT_COINS: `${RESOURCE}/CONVERT_COINS`,
};

export default RewardActionTypes;
