import PostActionTypes from "./actionTypes";
import BaseActions from "../../base/BaseActions";

const RESOURCE = "Post";

const PostActions = {
  ...BaseActions(RESOURCE, PostActionTypes),
};

export default PostActions;
