import UserActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const { REACT_APP_HOST } = process.env;
const RESOURCE = "User";

function register(data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${REACT_APP_HOST}/register`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      console.log({ response });

      dispatch({ type: UserActionTypes.REGISTER });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function login(data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${REACT_APP_HOST}/login`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      const { message } = response;
      const { id } = message || {};

      dispatch({
        type: UserActionTypes.LOGIN,
        data: { id },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function verify(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/verify`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { token },
        true,
        headers
      );
      dispatch({
        type: UserActionTypes.VERIFY,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function forgetPassword(email) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${REACT_APP_HOST}/ForgetPassword`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { credential: email },
        true,
        headers
      );
      dispatch({
        type: UserActionTypes.FORGET_PASSWORD,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function resetPassword(token, password) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${REACT_APP_HOST}/ResetPassword`,
      null,
      null
    );

    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { token, password },
        true,
        headers
      );
      dispatch({
        type: UserActionTypes.RESET_PASSWORD,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getQrCodeToken(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${REACT_APP_HOST}/me/qr`,
      null,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const result = response?.message?.record?.token;

      dispatch({
        type: UserActionTypes.GET_QRCODE,
        data: { result },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function clearQrCode() {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.CLEAR_QRCODE,
      data: null,
    });
  };
}

const UserActions = {
  ...BaseActions(RESOURCE, UserActionTypes),
  register,
  login,
  verify,
  forgetPassword,
  resetPassword,
  getQrCodeToken,
  clearQrCode,
};
export default UserActions;
