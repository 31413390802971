const ErrorHandler = (error) => {
  const errMsg =
    error?.error?.message ||
    error?.error?.msg ||
    error?.message ||
    error?.msg ||
    "Unknown Error. Please try again.";
  // window.alert(errMsg);
  console.log(errMsg);
  console.log(error);
};

export const ErrorHandlerWithAlert = (error) => {
  const errMsg =
    error?.error?.message ||
    error?.error?.msg ||
    error?.message ||
    error?.msg ||
    "Unknown Error. Please try again.";
  window.alert(errMsg);
  // console.log(errMsg);
  // console.log(error);
};

export const UncaughtRejectionHandler = (event) => {
  ErrorHandler(event.reason);
  ErrorHandlerWithAlert(event.reason);
};

export default ErrorHandler;
