import MeActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "Me";

function getMe(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_ME,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getCoupons(token, status, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/coupon/${status}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_COUPONS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getOrders(token, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/orders`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_ORDERS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getPurchasedItems(token, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/purchased_items`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_PURCHASED_ITEMS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getWallet(token, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/wallet`,
      params,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_WALLET,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getWalletHistory(token, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/wallet/history`,
      params,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_WALLET_HISTORY,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getRewardHistory(token, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/reward/history`,
      params,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_REWARD_HISTORY,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getReward(token, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/reward`,
      params,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.GET_REWARD,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function claimDailyReward(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/reward/daily`,
      null,
      token
    );

    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: MeActionTypes.CLAIM_DAILY,
      });
      return Promise.resolve(response);
    } catch (error) {
      if (error?.code === 54003)
        dispatch({
          type: MeActionTypes.CLAIM_DAILY,
        });

      return Promise.resolve(error);
    }
  };
}

function clearClaim() {
  return async (dispatch) => {
    dispatch({
      type: MeActionTypes.CLAIM_DAILY_RESET,
    });
  };
}

const MeActions = {
  ...BaseActions(RESOURCE, MeActionTypes),
  getMe,
  getCoupons,
  getOrders,
  getPurchasedItems,
  getWallet,
  getWalletHistory,
  getReward,
  getRewardHistory,
  claimDailyReward,
  clearClaim,
};
export default MeActions;
