import Immutable from "seamless-immutable";
import CartActionTypes from "./actionTypes";

const initialState = Immutable({
  _id: null,
  items: [],
  itemsCount: 0,
  itemsQty: 0,
  isGuest: true,
});

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CartActionTypes.GET_ONE:
      return { ...state, ...action.data };
    case CartActionTypes.RESET_CART:
      return initialState;
    case CartActionTypes.CHECKOUT:
      return { ...state, ...action.data };
    case CartActionTypes.MERGE_CART:
      return { ...state, ...action.data };
    case CartActionTypes.ALLOCATE_CART:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default CartReducer;
