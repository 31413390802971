import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Order";

const OrderActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_ORDER_STATUS: `${RESOURCE}/GET_ORDER_STATUS`,
};

export default OrderActionTypes;
