import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "delivery-provider";

const DeliveryProviderActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_ONE_PROVIDER_LOCKER: `${RESOURCE}/GET_ONE_PROVIDER_LOCKER`,
  GET_ONE_PROVIDER_REGION: `${RESOURCE}/GET_ONE_PROVIDER_REGION`,
  GET_ONE_PROVIDER_DISTRICT: `${RESOURCE}/GET_ONE_PROVIDER_DISTRICT`,
};

export default DeliveryProviderActionTypes;
