import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import configureStore from "./redux/store";
import Views from "./views";
import "./assets/scss/style.scss";
import i18n from "./i18n";
import ErrorBoundary from "./components/ErrorBoundary";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        <Views />
      </ErrorBoundary>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
