import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "compare";
const CompareActionTypes = {
  ...BaseActionTypes(RESOURCE),
  ADD_TO_COMPARE: `${RESOURCE}/ADD_TO_COMPARE`,
  DELETE_FROM_COMPARE: `${RESOURCE}/DELETE_FROM_COMPARE`,
};

export default CompareActionTypes;
