import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "blog";

const BlogActionTypes = {
  ...BaseActionTypes("blog"),
  FETCH_POSTS: `${RESOURCE}/FETCH_POSTS`,
};

export default BlogActionTypes;
