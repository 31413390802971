import APIHelper from "./APIHelper";

export const DEFAULT_HEADERS = {
  Accept: "application/json, text/plain, /",
  "Content-Type": "application/json",
};

export const APIRequestGenerator = (rawURL, params = null, token = null) => {
  let url = new URL(rawURL);
  if (params && typeof params === "object") {
    Object.keys(params).forEach((key) => {
      if (Array.isArray(params[key])) {
        params[key].forEach((paramItem) => {
          url.searchParams.append(`${key}[]`, paramItem);
        });
      } else {
        url.searchParams.set(key, params[key]);
      }
    });
  }
  const headers = { ...DEFAULT_HEADERS };
  if (token) headers.Authorization = `Basic ${token}`;
  // if (token) url.searchParams.set("access_token", token);
  url = url.toString();
  return { url, headers };
};

const BaseActions = (resource, ActionTypes) => {
  function create(token, data) {
    return async (dispatch) => {
      const { url, headers } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}`,
        null,
        token
      );
      try {
        const response = await APIHelper(
          "POST",
          url.toString(),
          data,
          true,
          headers
        );
        dispatch({
          type: ActionTypes.CREATE,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  function getList(token, params) {
    return async (dispatch) => {
      const { url, headers } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}`,
        params,
        token
      );
      try {
        const response = await APIHelper(
          "GET",
          url.toString(),
          null,
          true,
          headers
        );
        dispatch({
          type: ActionTypes.GET_LIST,
          data: response,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  function getOne(token, id, params) {
    return async (dispatch) => {
      const { url, headers } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}/${id}`,
        params,
        token
      );
      try {
        const response = await APIHelper(
          "GET",
          url.toString(),
          null,
          true,
          headers
        );
        dispatch({
          type: ActionTypes.GET_ONE,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  function updateOne(token, id, data) {
    return async (dispatch) => {
      const { url, headers } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}/${id}`,
        null,
        token
      );
      try {
        const response = await APIHelper(
          "PUT",
          url.toString(),
          data,
          true,
          headers
        );
        dispatch({
          type: ActionTypes.UPDATE_ONE,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  function deleteOne(token, id) {
    return async (dispatch) => {
      const { url, headers } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}/${id}`,
        null,
        token
      );
      try {
        const response = await APIHelper(
          "DELETE",
          url.toString(),
          null,
          true,
          headers
        );
        dispatch({
          type: ActionTypes.DELETE_ONE,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  function guestGetList(params) {
    return async (dispatch) => {
      const { url, headers } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/guest/${resource}`,
        params
      );
      try {
        const response = await APIHelper(
          "GET",
          url.toString(),
          null,
          true,
          headers
        );
        dispatch({
          type: ActionTypes.GET_LIST,
          data: response,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  function guestGetOne(identifier, params) {
    return async (dispatch) => {
      const { url, headers } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/guest/${resource}/${identifier}`,
        params
      );
      try {
        const response = await APIHelper(
          "GET",
          url.toString(),
          null,
          true,
          headers
        );
        dispatch({
          type: ActionTypes.GET_LIST,
          data: response,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  return {
    create,
    getList,
    getOne,
    updateOne,
    deleteOne,
    guestGetList,
    guestGetOne,
  };
};

export default BaseActions;
