import TokenActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "token";

function claim(token, data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/claim`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: TokenActionTypes.CLAIM,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const TokenActions = {
  ...BaseActions(RESOURCE, TokenActionTypes),
  claim,
};
export default TokenActions;
