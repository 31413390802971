import MerchantActionTypes from "./actionTypes";
import BaseActions from "../../base/BaseActions";

const RESOURCE = "Merchant";

const MerchantActions = {
  ...BaseActions(RESOURCE, MerchantActionTypes),
};

export default MerchantActions;
