export default {
  loading: "Cargando",
  homepage: "Página principal",
  search: "Buscar",
  default: "Defecto",
  save: "guardar",
  noRecord: "No hay registros",
  add: "Añadir",
  date: "Fecha",
  delete: "Eliminar",
  quit: "Dejar",
  navMenu: {
    login: "Registrarse",
    register: "Registrarse",
    myAccount: "Mi cuenta",
    logout: "Finalizar la sesión",
    quantity: "Cantidad",
    sku: "Modelo",
    option: "Opción",
    total: "Total",
    viewCart: "Ver carrito de compras",
    checkout: "Revisa",
    noItemInCart: "Ningún artículo en su carrito",
    aboutUs: "Sobre nosotros",
    redemption: "Customer Redemption",
    balance: "Coins Balance: {{coins}}",
    sessionExpired: "Your session has expired. Please login again.",
  },
  footer: {
    aboutus: "Sobre nosotros",
    contact: "contact",
    usefulLinks: "Enlaces útiles",
    disclaimer: "Descargo de responsabilidad",
    policy: "Políticas de privacidad",
    tnc: "Terms and Conditions",
    guide: "Shopping Guide",
    subscribeUs: "Suscríbete",
    Facebook: "Facebook",
    Youtube: "Youtube",
    Instagram: "Instagram",
  },
  home: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    buyNow: "Compra ahora",
    latestProducts: "Nuevos llegadas",
    latestProductsSubTitle: "Proporcionarle los últimos productos cada mes",
    new: "Nueva",
    selectOptions: "Seleccione opciones",
    addToCart: "Añadir al carrito",
    addedToCart: "Añadido al carrito",
    outOfStock: "Agotado",
    view: "Ver",
    addedToWishlist: "Añadido a la lista de deseos",
    addToWishlist: "Añadir a la lista de deseos",
    blogTitle: "How's our KOL say?",
    writtenBy: "Written by",
    featureTitle1: "2 FREE SAMPLES",
    featureSubTitle1: "With Every Order",
    featureTitle2: "FREE NEXT DAY DELIVERY",
    featureSubTitle2: "Order by 12 pm. HK Only.",
    featureTitle3: "FREE GIFT WRAP",
    featureSubTitle3: "Select At Checkout",
    bannerTitle1: "",
    bannerTitle2: "",
    viewMoreProducts: "Ver más productos",
  },
  productList: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    allProducts: "Todos los productos",
    priceLowToHigh: "Precio - Bajo a Alto",
    priceHighToLow: "Precio - Alto a Bajo",
    updateTimeNewToOld: "Tiempo de actualización - nuevo a viejo",
    updateTimeOldToNew: "Tiempo de actualización - viejo a nuevo",
    categories: "Categorías",
    noCategories: "Todas las categorias",
    buyNow: "Compra ahora",
    new: "Nuevo",
    selectOptions: "Seleccione opciones",
    addToCart: "Añadir al carrito",
    addedToCart: "Añadido al carrito",
    outOfStock: "Agotado",
    view: "Ver",
    addedToWishlist: "Añadido a la lista de deseos",
    addToWishlist: "Añadir a la lista de deseos",
    addedToCompare: "Agregado para comparar",
    addToCompare: "Añadir a comparar",
    brands: "Brands",
    noBrands: "All Brands",
    point: "points",
  },
  productDetails: {
    metaTitle: "Kingsmart | Product Detail",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    productDetails: "Detalles de producto",
    sku: "Modelo",
    option: "Opción",
    buyNow: "Compra ahora",
    addToCart: "Añadir al carrito",
    outOfStock: "Agotado",
    additionalInfo: "Información Adicional",
    alsoBuy: "You may also like",
    quantity: "Cantidad",
    packageTotal: "Total del paquete",
    relatedProducts: "También te puede interesar",
    relatedCategories: "Related Categories",
    alsoLike: "You may also like",
    categories: "Categories",
    packageDetails: "Package Details",
    preferredDate: "Preferred Date",
    preferredTime: "Preferred Time",
    remark: "Remark",
    select: "Select",
    simplrStart: "Start Time",
    simplrEnd: "End Time",
    simplrInvoice: "Booking Reference",
    point: "points",
  },
  loginRegister: {
    metaTitle: "Kingsmart | Registrarse / Registrarse",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    loginRegister: "Registrarse｜Registrarse",
    login: "Registrarse",
    register: "Registrarse",
    email: "Correo electrónico",
    password: "Contraseña",
    rememberMe: "Recuérdame",
    forgotPassword: "Contraseña olvidada",
    firstName: "Nombre de pila",
    lastName: "Apellido",
    phone: "Móvil",
    loginPanelTitle: "¿Ya eres usuario?",
    registerPanelTitle: "¡Únete a nosotros hoy!",
    registerPanelSubTitle:
      "Registrarse para ser miembro. ¡Empiece a estar atento a nuestras últimas novedades!",
    registerModalTitle: "Registro de miembros",
    resetPasswordMsg:
      "We will send an email to your email address for reset password if your record is found in our system.",
    validateAccountMsg: "Email validation success.",
    referralCode: "Referral Code",
  },
  myAccount: {
    metaTitle: "Kingsmart | My Account",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    myAccount: "My Account",
    personalInfo: "Información de la cuenta",
    deliveryAddress: "Dirección de entrega",
    orderRecord: "Registros de pedidos",
    personalInfoTitle: "Tu información personal",
    firstName: "Nombre de pila",
    lastName: "Apellido",
    phone: "Teléfono",
    email: "Correo electrónico",
    address: "Dirección",
    changePassword: "Cambia la contraseña",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirmar contraseña",
    addNewAddress: "Nueva dirección de envío",
    addressLine1: "Dirección 1",
    addressLine2: "Dirección 2",
    addressLine3: "Ciudad",
    stateProvince: "Estato/Provincia",
    zipCode: "Código postal",
    countryRegion: "País / Región",
    order: "Orden",
    orderId: "Orden ID",
    orderStatus: "Estado del pedido",
    paymentStatus: "Estado de pago",
    deliveryStatus: "Estado de entrega",
    total: "Total",
    details: "Detalles",
    receiverName: "Nombre del destinatario",
    contactPhone: "Número de contacto",
    image: "Imagen",
    productName: "Nombre del producto",
    unitPrice: "Precio unitario",
    quantity: "Cantidad",
    subTotal: "Total parcial",
    sku: "Modelo",
    membership: "Miembro",
    coupons: "Cupones",
    option: "Opción",
    membershipTier: "Membership Tier",
    amountSpent: "You have spent {{amount}} within 12 months",
    credit: "Credit",
    totalCredit: "Total Credit",
    expiryDate: "Expiry Date",
    dollars: "{{amount}} Coins",
    date: "Date",
    event: "Event",
    silver: "Silver",
    gold: "Gold",
    discountName: "Discount Name",
    discountAmount: "Discount Amount",
    productDetails: "Product Details",
    discountDetails: "Discount Details",
    shippingFee: "Shipping Fee",
    preferredDate: "Booking Date",
    preferredTime: "Booking Time",
    remark: "Remark",
    status: "Status",
    redemption: "Redemption",
    noResults: "No Results",
    adjust: "Adjust",
    pay: "Pay",
    topup: "Top-up",
    rebate: "Rebate",
    reward: "Reward",
    totalPoint: "Total Point",
    points: "Points",
    welcome: "Welcome",
    firstPurchase: "First Purchase",
    dailyLogin: "Daily Login",
    referral: "Referral",
    convert: "Convert",
    convertToKcoin: "Convert to Coins",
    youHave: "You have",
    exchangeTo: "You can exchange to",
    purchasedContent: "View Purchased Content",
  },
  cart: {
    metaTitle: "Kingsmart | Cart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    cart: "Carro",
    yourCart: "Su carrito",
    image: "Imagen",
    productName: "nombre del producto",
    unitPrice: "Precio unitario",
    quantity: "Cantidad",
    subTotal: "Total parcial",
    sku: "Modelo",
    option: "Opción",
    continueShopping: "Seguir comprando",
    clearCart: "Vaciar carrito",
    useCoupon: "Usar código de cupón",
    inputCouponCode: "Seleccione / inserte su código de cupón aquí",
    applyCouponCode: "Aplicar código de cupón",
    cartTotal: "Total del carrito",
    totalDiscount: "Descuento total",
    total: "gran total",
    continueCheckout: "pasar por la caja",
    noItemInCart: "Ningún artículo en su carrito",
    buyNow: "Compra ahora",
    noStock: "Item out of stock",
    linkAccount: "Link up Customer's Account",
    checkout: "Checkout Now",
    disabledItems: "Invalid Items",
    removeDisabled: "(Please remove the below items before checkout)",
    removeAllDisabled: "Remove all invalid items from cart",
  },
  checkout: {
    metaTitle: "Kingsmart | Checkout",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    checkout: "Revisa",
    billingDetails: "Billing Details",
    firstName: "Nombre de pila",
    lastName: "Apellido",
    phone: "Teléfono",
    email: "Correo electrónico",
    companyName: "Nombre",
    addressLine1: "Dirección 1",
    addressLine2: "Dirección 2",
    addressLine3: "Ciudad",
    stateProvince: "Estato/Provincia",
    zipCode: "Código postale",
    countryRegion: "País / Región",
    additionalInfo: "Información Adicional",
    otherRemarks: "Otras observaciones",
    yourOrder: "Su pedido",
    product: "Producto",
    total: "Amount Payable",
    delivery: "Entrega",
    discount: "Descuento",
    totalDiscount: "Descuento total",
    freeShipping: "Envío gratis",
    submitOrder: "realizar pedido",
    noItemInCart: "Ningún artículo en su carrito",
    buyNow: "Compra ahora",
    confirmBooking:
      "Staff will confirm your booking very soon. Updates will be sent to your mailbox.",
    insufficientCoin:
      'There is insufficient K coins in your account. If you wish to checkout by credit card, please click "Yes".',
    staffCode: "Input Staff Code",
    apply: "Apply",
    tokenError: "QR Code has been expired or revoked.",
    required: "Required",
    optional: "Optional",
    redemption: "Redeem Credits",
    remove: "Remove",
    failedToCheckout:
      "Unable to checkout this order. Please review your cart items.",
    wrongStaffCode: "Please input correct staff code",
    insufficientPoint: "Insufficient Point",
    district: "District",
    area: "Area",
    locker: "Pick Up Point",
    lockerReminder:
      "If total weight of the products is >5kg, we will contact you separately to arrange delivery",
    lockerAddress: "Address",
    lockerOpenHours: "Open Hours",
    flat: "Flat/Room",
    floor: "Floor",
    block: "Block",
    building: "Building",
    street: "Street",
  },
  paymentNotification: {
    metaTitle: "Kingsmart | Payment Result",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    paymentNotification: "Notificacion de pago",
    backToHome: "De vuelta a casa",
    orderSuccessTitle: "Pedido exitoso",
    orderSuccessMsg:
      "¡Gracias por comprar! Hemos recibido su pedido y consulte su correo electrónico para obtener más información.",
    orderCancelledTitle: "Orden cancelada",
    orderCancelledMsg:
      "Su orden puede ser cancelada. Póngase en contacto con nuestro servicio de atención al cliente para obtener más información.",
    orderPendingTitle: "Orden pendiente",
    orderPendingMsg:
      "¡Gracias por comprar! Hemos recibido su pedido y consulte su correo electrónico para obtener más información.",
  },
  aboutUs: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    aboutUs: "Sobre nosotros",
  },
  blog: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    relatedProducts: "Producto relacionado",
    relatedCategories: "Related Categories",
    blogPost: "Entrada en el blog",
  },
  wishlist: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    wishlist: "Lista de deseos",
    image: "Imagen",
    productName: "nombre del producto",
    unitPrice: "Precio unitario",
    selectOptions: "Seleccione opciones",
    addToCart: "Añadir al carrito",
    addedToCart: "Añadido al carrito",
    outOfStock: "Agotado",
    continueShopping: "Seguir comprando",
  },
  compare: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    compare: "Compare",
    product: "Product",
    unitPrice: "Unit Price",
    description: "Description",
    sku: "Model",
    option: "Option",
    additionalInfo: "Additional information",
    view: "View",
    continueShopping: "Continue Shopping",
  },
  resetPassword: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    resetPassword: "Reset Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
  },
  alerts: {
    accountNotFound: "Account not found",
    wrongEmail: "Please provide correct email",
    wrongConfirmPassword: "New and confirm password are different",
    wrongPasswordFormat:
      "Please fill in correct password.\n" +
      "Password consists of at least 8 characters, which must include number and letters (at least one capital letter)",
    wrongPhoneFormat: "Please provide correct mobile",
    fillNecessaryInfo: "Please fill in all necessary information",
    updateAccountInfoSuccess: "Information has been updated",
    updatePasswordSuccess: "Password has been updated",
    referencePriceRemarks: "Reference price",
  },
  qrCodeScanner: {
    scan: "Scan QR Code",
    enableAccess: "Please Enable Camera Access",
    or: "or",
    inputMobile: "Input customer's mobile",
    submit: "Submit",
  },
  customerRedemption: {
    metaTitle: "Kingsmart | Customer Redemption",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "Booking Items",
    image: "Image",
    productDetails: "Product Details",
    unitPrice: "Unit Price",
    staffCode: "Staff Code",
    redeem: "Redeem",
    invalidCode: "Invalid QR Code",
    enterMobile: "Please enter customer mobile",
    redeemed: "Redeemed",
    enterCode: "Please enter staff code",
    redeemedAt: "Redemption Date",
    expiredAt: "Expiry Date",
    cancelledAt: "Cancellation Date",
    available: "Available",
    expired: "Expired",
    cancelled: "Cancelled",
    noResults: "No Results",
  },
  bookingConfirmation: {
    metaTitle: "Kingsmart | Booking Confirmation",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "Booking Items",
    image: "Image",
    productDetails: "Product Details",
    unitPrice: "Unit Price",
    staffCode: "Staff Code",
    redeem: "Redeem",
    redeemed: "Redeemed",
    enterCode: "Please enter staff code",
    preferredDate: "Preferred Date",
    preferredTime: "Preferred Time",
    remark: "Remark",
    submit: "Submit",
    book_success: "Service has been updated",
    pending: "Pending",
    revoked: "Revoked",
    expired: "Expired",
  },
  coupon: {
    active: "Active",
    event: "Event",
    code: "Code",
    purchase_reached: "Purchase Reached",
    expiry_date: "Expiry Date",
    redeemed_expired: "Redeemed / Expired",
  },
  simplr: {
    unavailable: "Period unavailable. Please try another timeslot",
  },
};
