import DeliveryProviderActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "delivery-provider";

function getOneProviderLocker(token, providerId) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/${providerId}/locker`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: DeliveryProviderActionTypes.GET_ONE_PROVIDER_LOCKER,
        data: response,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getOneProviderRegion(token, providerId) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/${providerId}/region`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: DeliveryProviderActionTypes.GET_ONE_PROVIDER_REGION,
        data: response,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getOneProviderDistrict(token, providerId, searchParams = {}) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}/${providerId}/district`,
      JSON.parse(JSON.stringify(searchParams)),
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: DeliveryProviderActionTypes.GET_ONE_PROVIDER_DISTRICT,
        data: response,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const DeliveryProviderActions = {
  ...BaseActions(RESOURCE, DeliveryProviderActionTypes),
  getOneProviderLocker,
  getOneProviderRegion,
  getOneProviderDistrict,
};

export default DeliveryProviderActions;
