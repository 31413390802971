import CurrencyActionTypes from "./actionTypes";

function setCurrency(
  currencyName = "HKD",
  currencyRate = 1,
  defaultCurrencyName = null
) {
  return async (dispatch) => {
    try {
      let currencySymbol;
      switch (currencyName.toLowerCase()) {
        case "eur":
          currencySymbol = "€";
          break;
        case "gbp":
          currencySymbol = "£";
          break;
        case "krw":
          currencySymbol = "₩";
          break;
        case "jpy":
          currencySymbol = "¥";
          break;
        default:
          currencySymbol = "$";
          break;
      }

      const data = {
        currencyName,
        currencyRate,
        currencySymbol,
      };

      if (defaultCurrencyName) {
        data.defaultCurrencyName = defaultCurrencyName;
      }

      dispatch({
        type: CurrencyActionTypes.SET_CURRENCY,
        data,
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const CurrencyActions = {
  setCurrency,
};
export default CurrencyActions;
