import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "discount";

const DiscountActionTypes = {
  ...BaseActionTypes(RESOURCE),
  CHECK_DISCOUNT: `${RESOURCE}/CHECK_DISCOUNT`,
};

export default DiscountActionTypes;
