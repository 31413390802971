import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import App from "./App";

const Views = () => (
  <ToastProvider placement="bottom-left">
    <BreadcrumbsProvider>
      <Router>
        <App />
      </Router>
    </BreadcrumbsProvider>
  </ToastProvider>
);

export default Views;
