import Immutable from "seamless-immutable";
import CategoryActionTypes from "./actionTypes";

const initialState = Immutable([]);

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CategoryActionTypes.GET_LIST:
      return action.data.message.records;
    default:
      return state;
  }
};

export default CategoryReducer;
