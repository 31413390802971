import FloneProductActionTypes from "./actionTypes";

const { FETCH_PRODUCTS_SUCCESS } = FloneProductActionTypes;

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

// fetch products
const fetchProducts = (products) => (dispatch) => {
  dispatch(fetchProductsSuccess(products));
};

const FloneProductActions = { fetchProducts };
export default FloneProductActions;
