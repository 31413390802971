import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "wishlist";

const WishlistActionTypes = {
  ...BaseActionTypes(RESOURCE),
  FETCH_WISHLIST: `${RESOURCE}/FETCH_WISHLIST`,
  DELETE_WISHLIST_ITEM: `${RESOURCE}/DELETE_WISHLIST_ITEM`,
  CLEAR_WISHLIST_DISPLAY: `${RESOURCE}/CLEAR_WISHLIST_DISPLAY`,
  CHECK_IS_IN_WISHLIST: `${RESOURCE}/CHECK_IS_IN_WISHLIST`,
};

export default WishlistActionTypes;
