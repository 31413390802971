import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "token";

const TokenActionTypes = {
  ...BaseActionTypes(RESOURCE),
  CLAIM: `${RESOURCE}/CLAIM`,
};

export default TokenActionTypes;
