import Immutable from "seamless-immutable";
import CompareActionTypes from "./actionTypes";

const initialState = Immutable([]);

const CompareReducer = (state = initialState, action) => {
  switch (action.type) {
    case CompareActionTypes.ADD_TO_COMPARE:
      return action.payload;
    case CompareActionTypes.DELETE_FROM_COMPARE:
      return action.payload;
    default:
      return state;
  }
};

export default CompareReducer;
