import PaymentMethodActionTypes from "./actionTypes";
import BaseActions from "../../base/BaseActions";

const RESOURCE = "PaymentMethod";

const PaymentMethodActions = {
  ...BaseActions(RESOURCE, PaymentMethodActionTypes),
};

export default PaymentMethodActions;
