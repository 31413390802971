import { combineReducers } from "redux";
import currencyReducer from "./currency/reducer";
import productReducer from "./flone/product/reducer";
import ContentReducer from "./content/reducer";
import ProductReducer from "./product/reducer";
import BlogReducer from "./blog/reducer";
import HashTagReducer from "./hashtag/reducer";
import CategoryReducer from "./category/reducer";
import UserReducer from "./user/reducer";
import WishlistReducer from "./wishlist/reducer";
import CartReducer from "./cart/reducer";
import MeReducer from "./me/reducer";
import DiscountReducer from "./discount/reducer";
import OrderReducer from "./order/reducer";
import CompareReducer from "./compare/reducer";
import StoreConfigReducer from "./storeConfig/reducer";
import PaymentMethodReducer from "./paymentMethod/reducer";
import ShippingMethodReducer from "./shippingMethod/reducer";
import MerchantReducer from "./merchant/reducer";
import PurchasedItemReducer from "./purchasedItem/reducer";
import TokenReducer from "./token/reducer";
import RewardReducer from "./reward/reducer";
import DeliveryProviderReducer from "./deliveryProvider/reducer";

export default combineReducers({
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: CartReducer,
  wishlistData: WishlistReducer,
  compareData: CompareReducer,
  content: ContentReducer,
  product: ProductReducer,
  blog: BlogReducer,
  hashtag: HashTagReducer,
  category: CategoryReducer,
  user: UserReducer,
  me: MeReducer,
  discount: DiscountReducer,
  order: OrderReducer,
  storeConfig: StoreConfigReducer,
  paymentMethod: PaymentMethodReducer,
  shippingMethod: ShippingMethodReducer,
  merchant: MerchantReducer,
  purchasedItem: PurchasedItemReducer,
  token: TokenReducer,
  reward: RewardReducer,
  deliveryProvider: DeliveryProviderReducer,
});
