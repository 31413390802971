import Immutable from "seamless-immutable";
import DeliveryProviderActionTypes from "./actionTypes";

const initialState = Immutable([]);

const DeliveryProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case DeliveryProviderActionTypes.GET_LIST:
      return action.data.message.records;
    default:
      return state;
  }
};

export default DeliveryProviderReducer;
