import Immutable from "seamless-immutable";
import CurrencyActionTypes from "./actionTypes";

const initialState = Immutable({
  currencySymbol: "$",
  currencyName: "HKD",
  currencyRate: 1,
  defaultCurrencyName: "HKD",
});

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CurrencyActionTypes.SET_CURRENCY:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default ContentReducer;
