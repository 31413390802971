import CategoryActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "ProductCategory";

function getBySlug(token, slug) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${RESOURCE}`,
      { slug },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: CategoryActionTypes.GET_BY_SLUG,
        data: response,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const CategoryActions = {
  ...BaseActions(RESOURCE, CategoryActionTypes),
  getBySlug,
};

export default CategoryActions;
