import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "User";

const UserActionTypes = {
  ...BaseActionTypes(RESOURCE),
  REGISTER: `${RESOURCE}/REGISTER`,
  LOGIN: `${RESOURCE}/LOGIN`,
  VERIFY: `${RESOURCE}/VERIFY`,
  FORGET_PASSWORD: `${RESOURCE}/FORGET_PASSWORD`,
  RESET_PASSWORD: `${RESOURCE}/RESET_PASSWORD`,
  GET_QRCODE: `${RESOURCE}/QRCODE`,
  CLEAR_QRCODE: `${RESOURCE}/CLEAR_QRCODE`,
};

export default UserActionTypes;
