import Immutable from "seamless-immutable";
import UsersActionTypes from "./actionTypes";

const initialState = Immutable({
  _id: "",
  qrCodeToken: "",
});

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UsersActionTypes.LOGIN:
      return {
        ...state,
        _id: action.data.id,
      };
    case UsersActionTypes.GET_QRCODE:
      return {
        ...state,
        qrCodeToken: action.data.result,
      };
    case UsersActionTypes.CLEAR_QRCODE:
      return {
        ...state,
        qrCodeToken: null,
      };
    default:
      return state;
  }
};

export default UsersReducer;
