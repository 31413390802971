import Immutable from "seamless-immutable";
import RewardActionTypes from "./actionTypes";

const initialState = Immutable({});

const RewardReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default RewardReducer;
