import CompareActionTypes from "./actionTypes";
import BaseActions from "../../base/BaseActions";

const RESOURCE = "compare";

function addToCompare(selectedItem, compareItems = []) {
  return async (dispatch) => {
    try {
      const product = selectedItem;
      let payload;
      const compareItem = compareItems.find((item) => item._id === product._id);
      if (!compareItem) {
        payload = [...compareItems, product];
      } else {
        payload = compareItems;
      }

      dispatch({
        type: CompareActionTypes.ADD_TO_COMPARE,
        payload,
      });
      return Promise.resolve(payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function deleteFromCompare(selectedItem, compareItems) {
  return async (dispatch) => {
    try {
      const product = selectedItem;
      const payload = compareItems.filter(
        (compareItem) => compareItem._id !== product._id
      );

      dispatch({
        type: CompareActionTypes.DELETE_FROM_COMPARE,
        payload,
      });
      return Promise.resolve(payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const CompareActions = {
  ...BaseActions(RESOURCE, CompareActionTypes),
  deleteFromCompare,
  addToCompare,
};
export default CompareActions;
