import Immutable from "seamless-immutable";
import DiscountActionTypes from "./actionTypes";

const initialState = Immutable({});

const DiscountReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default DiscountReducer;
