import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Cart";

const CartActionTypes = {
  ...BaseActionTypes(RESOURCE),
  ALLOCATE_CART: `${RESOURCE}/ALLOCATE_CART`,
  RESET_CART: `${RESOURCE}/RESET_CART`,
  CHECKOUT: `${RESOURCE}/CHECKOUT`,
  MERGE_CART: `${RESOURCE}/MERGE_CART`,
};

export default CartActionTypes;
