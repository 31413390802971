import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "ProductCategory";

const CategoryActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_BY_SLUG: `${RESOURCE}/GET_BY_SLUG`,
};

export default CategoryActionTypes;
