import ShippingMethodActionTypes from "./actionTypes";
import BaseActions from "../../base/BaseActions";

const RESOURCE = "ShippingMethod";

const ShippingMethodActions = {
  ...BaseActions(RESOURCE, ShippingMethodActionTypes),
};

export default ShippingMethodActions;
