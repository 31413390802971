import Immutable from "seamless-immutable";
import ContentActionTypes from "./actionTypes";

const initialState = Immutable({
  data: [
    {
      name_en: "PinNBuy",
      name_zh: "PinNBuy",
      logo: "",
      banner: [],
      __v: 0,
      about: [],
      name: {
        zh: "PinNBuy",
        en: "PinNBuy",
      },
      aboutUsLink: [],
      socialLink: [],
      usefulLink: [],
      contact: [
        {
          name: {
            en: "Phone",
            zh: "電話",
          },
          text: "23452345",
        },
      ],
      disclaimer: {
        en: "<p>Disclimer</p>",
      },
      policy: {
        en: "<p>Policy</p>",
      },
      tnc: {
        en: "<p>Terms and Conditions<br></p>",
      },
      guide: {
        en: "<p>Guides</p>",
      },
      exchangeRate: {
        hkd: 1,
      },
    },
  ],
  intl: {
    languages: ["en", "zh"],
    defaultLanguage: "en",
  },
  currency: {
    support: ["hkd"],
    primary: "hkd",
  },
});

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ContentActionTypes.GET_LIST:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default ContentReducer;
