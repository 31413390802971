import Immutable from "seamless-immutable";
import moment from "moment";
import MeActionTypes from "./actionTypes";

const initialState = Immutable({ claimDate: "" });

const MeReducer = (state = initialState, action) => {
  switch (action.type) {
    case MeActionTypes.CLAIM_DAILY:
      return {
        ...state,
        claimDate: moment(new Date()).format("YYYY-MM-DD"),
      };
    case MeActionTypes.CLAIM_DAILY_RESET:
      return { ...state, claimDate: "" };
    case MeActionTypes.GET_WALLET:
      return { ...state, availableAmount: action.payload };
    default:
      return state;
  }
};

export default MeReducer;
