import Immutable from "seamless-immutable";
import WishlistActionTypes from "./actionTypes";

const initialState = Immutable({
  wishlistedProducts: [],
  wishlistItems: [],
});

const WishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case WishlistActionTypes.CHECK_IS_IN_WISHLIST:
      return { ...state, wishlistedProducts: action.payload };
    case WishlistActionTypes.CLEAR_WISHLIST_DISPLAY:
      return initialState;
    case WishlistActionTypes.FETCH_WISHLIST:
      return { ...state, wishlistItems: action.payload };
    default:
      return state;
  }
};

export default WishlistReducer;
