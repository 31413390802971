import OrderActionTypes from "./actionTypes";
import { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

function getOrderStatus(id) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/order-status/${id}`
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: OrderActionTypes.GET_ORDER_STATUS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const OrderActions = {
  getOrderStatus,
};
export default OrderActions;
