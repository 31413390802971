import WishlistActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "wishlist";
const { REACT_APP_HOST } = process.env;

function clearWishlistDisplay() {
  return async (dispatch) => {
    dispatch({ type: WishlistActionTypes.CLEAR_WISHLIST_DISPLAY });
  };
}

function check(token, productIds = []) {
  return async (dispatch) => {
    const queryString = productIds.map((id) => `product[]=${id}`).join("&");
    const apiUrl = `${REACT_APP_HOST}/wishlist-check?${queryString}`;

    const { url, headers } = APIRequestGenerator(apiUrl, null, token);

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const { message } = response;
      const { records = [] } = message || {};

      dispatch({
        type: WishlistActionTypes.CHECK_IS_IN_WISHLIST,
        payload: records,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function fetchAll(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${REACT_APP_HOST}/${RESOURCE}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      const { message } = response;
      const { records = [] } = message || {};

      const listItems = records
        .filter(({ product }) => product)
        .map(({ product }) => product);

      dispatch({
        type: WishlistActionTypes.FETCH_WISHLIST,
        payload: listItems,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function deleteItem(token, productId) {
  return async (dispatch) => {
    if (!token) {
      dispatch({
        type: WishlistActionTypes.DELETE_WISHLIST_ITEM,
      });
      return Promise.reject({ msg: "Please login first" });
    }
    const { url, headers } = APIRequestGenerator(
      `${REACT_APP_HOST}/${RESOURCE}?product=${productId}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "DELETE",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: WishlistActionTypes.DELETE_WISHLIST_ITEM,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const WishlistActions = {
  ...BaseActions(RESOURCE, WishlistActionTypes),
  clearWishlistDisplay,
  check,
  deleteItem,
  fetchAll,
};
export default WishlistActions;
