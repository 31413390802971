import React from "react";

const Loading = ({ relative = false, style }) => (
  <div
    className={`flone-preloader-wrapper${
      relative ? " flone-preloader-wrapper-relative" : ""
    }`}
    style={style}
  >
    <div className="flone-preloader">
      <span />
      <span />
    </div>
  </div>
);

export default Loading;
