import Immutable from "seamless-immutable";

const initialState = Immutable({});

const TokenReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default TokenReducer;
