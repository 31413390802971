export default {
  loading: "Loading",
  homepage: "主页",
  search: "搜寻",
  default: "预设",
  save: "储存",
  noRecord: "没有纪录",
  add: "新增",
  date: "日期",
  delete: "删除",
  quit: "退出",
  navMenu: {
    login: "登入",
    register: "注册",
    myAccount: "我的帐户",
    logout: "登出",
    quantity: "数量",
    sku: "款式",
    option: "选项",
    total: "总计",
    viewCart: "检视购物车",
    checkout: "结算",
    noItemInCart: "购物车内没有货物",
    aboutUs: "关于我们",
    redemption: "商品兌換",
    balance: "Luxe Dollar 餘額: {{coins}}",
    sessionExpired: "您的使用期限已過，請重新登入。",
  },
  footer: {
    aboutus: "关于我们",
    contact: "联系我们",
    usefulLinks: "有用连结",
    disclaimer: "条款及细则",
    policy: "送货及退货政策",
    tnc: "使用条款",
    guide: "购物指南",
    subscribeUs: "关注我们",
    Facebook: "Facebook",
    Youtube: "Youtube",
    Instagram: "Instagram",
  },
  home: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    buyNow: "立即选购",
    latestProducts: "最新产品",
    latestProductsSubTitle: "送上每月最新产品",
    new: "新",
    selectOptions: "选择款式",
    addToCart: "新增至购物车",
    addedToCart: "已加到购物车",
    outOfStock: "即將推出",
    view: "检视",
    addedToWishlist: "已加到最爱",
    addToWishlist: "加到最爱",
    blogTitle: "How's our KOL say?",
    writtenBy: "编",
    featureTitle1: "2 FREE SAMPLES",
    featureSubTitle1: "With Every Order",
    featureTitle2: "FREE NEXT DAY DELIVERY",
    featureSubTitle2: "Order by 12 pm. HK Only.",
    featureTitle3: "FREE GIFT WRAP",
    featureSubTitle3: "Select At Checkout",
    bannerTitle1: "",
    bannerTitle2: "",
    viewMoreProducts: "更多產品",
  },
  productList: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    allProducts: "所有产品",
    priceLowToHigh: "价钱 - 低至高",
    priceHighToLow: "价钱 - 高至低",
    updateTimeNewToOld: "更新时间 - 新到旧",
    updateTimeOldToNew: "更新时间 - 旧到新",
    categories: "产品种类",
    noCategories: "所有",
    buyNow: "立即选购",
    new: "新",
    selectOptions: "选择款式",
    addToCart: "新增至购物车",
    addedToCart: "已加到购物车",
    outOfStock: "即將推出",
    view: "检视",
    addedToWishlist: "已加到最爱",
    addToWishlist: "加到最爱",
    addedToCompare: "已加到比较",
    addToCompare: "加到比较",
    brands: "品牌",
    noBrands: "所有",
    point: "積分",
  },
  productDetails: {
    metaTitle: "Kingsmart | 产品详情",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    productDetails: "产品详情",
    sku: "款式",
    option: "选项",
    buyNow: "立即选购",
    addToCart: "加入购物车",
    outOfStock: "即將推出",
    additionalInfo: "更多资料",
    alsoBuy: "你或許也喜歡",
    quantity: "件数",
    packageTotal: "套装总价",
    relatedProducts: "相关产品",
    alsoLike: "You may also like",
    relatedCategories: "相关产品种类",
    categories: "产品种类",
    packageDetails: "套装详情",
    preferredDate: "首選日期",
    preferredTime: "首選時間",
    remark: "備註",
    select: "選擇",
    simplrStart: "預約開始時間",
    simplrEnd: "預約完結時間",
    simplrInvoice: "預約參考編號",
    point: "積分",
  },
  loginRegister: {
    metaTitle: "Kingsmart | 登入 / 注册",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    loginRegister: "登入｜注册",
    login: "登入",
    register: "注册",
    email: "电邮地址",
    password: "密码",
    rememberMe: "记住我",
    forgotPassword: "忘记密码",
    firstName: "名字",
    lastName: "姓氏",
    phone: "电话",
    loginPanelTitle: "已经是会员?",
    registerPanelTitle: "立即加入!",
    registerPanelSubTitle: "登记成为会员以获取最新品牌资讯",
    registerModalTitle: "注册会员",
    resetPasswordMsg:
      "如您所输入的电邮地址符合我们的系统纪录，我们将会发送重置密码之电邮到您的电邮地址。",
    validateAccountMsg: "电邮地址认证成功",
    referralCode: "推薦碼",
  },
  myAccount: {
    metaTitle: "Kingsmart | 我的帐户",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    myAccount: "我的帐户",
    personalInfo: "个人资料",
    deliveryAddress: "送货地址",
    orderRecord: "订单记录",
    personalInfoTitle: "你的个人详情",
    firstName: "名字",
    lastName: "姓氏",
    phone: "电话",
    email: "电邮地址",
    changePassword: "更改密码",
    newPassword: "新密码",
    address: "地址",
    confirmPassword: "确认新密码",
    addNewAddress: "新增送货地址",
    addressLine1: "街道地址",
    addressLine2: "街道地址 2",
    addressLine3: "城市",
    stateProvince: "州 / 省",
    zipCode: "邮递编号",
    countryRegion: "国家 / 地区",
    order: "订单",
    orderId: "订单编号",
    orderStatus: "订单状态",
    paymentStatus: "付款状态",
    deliveryStatus: "送货状态",
    total: "总计",
    details: "详情",
    receiverName: "收件人姓名",
    contactPhone: "联络电话",
    image: "图片",
    productName: "产品名字",
    unitPrice: "单价",
    quantity: "数量",
    subTotal: "小计",
    sku: "款式",
    membership: "会员",
    coupons: "优惠券",
    option: "选项",
    membershipTier: "會員級別",
    amountSpent: "在過去12個月，你已消費 {{amount}}",
    credit: "積分",
    totalCredit: "積分總數",
    expiryDate: "到期日",
    dollars: "{{amount}} Coins",
    date: "日期",
    event: "事件",
    silver: "銀",
    gold: "金",
    discountName: "折扣名稱",
    discountAmount: "折扣金額",
    productDetails: "商品詳情",
    discountDetails: "折扣詳情",
    shippingFee: "運費",
    preferredDate: "預約日期",
    preferredTime: "預約時間",
    remark: "備註",
    status: "狀態",
    redemption: "兌換商品",
    noResults: "沒有結果",
    adjust: "調整",
    pay: "付款",
    topup: "充值",
    rebate: "回贈",
    reward: "獎賞計劃",
    totalPoint: "總點數",
    points: "點數",
    welcome: "迎新",
    firstPurchase: "首次購買",
    dailyLogin: "登入",
    referral: "朋友推薦",
    convert: "兌換",
    convertToKcoin: "兌換成Coins",
    youHave: "你有",
    exchangeTo: "你可以兌換",
    purchasedContent: "查看已購買內容",
  },
  cart: {
    metaTitle: "Kingsmart | 购物车",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    cart: "购物车",
    yourCart: "你的购物车",
    image: "图片",
    productName: "产品名字",
    unitPrice: "单价",
    quantity: "数量",
    subTotal: "小计",
    sku: "款式",
    option: "选项",
    continueShopping: "继续购物",
    clearCart: "清空购物车",
    useCoupon: "使用优惠码",
    inputCouponCode: "输入优惠码",
    applyCouponCode: "应用优惠码",
    cartTotal: "购物车总计",
    totalDiscount: "总折扣",
    total: "总计",
    continueCheckout: "继续结算",
    noItemInCart: "购物车内没有货物",
    buyNow: "立即选购",
    linkAccount: "連結客戶帳號",
    checkout: "立即結算",
    disabledItems: "已失效的商品",
    removeDisabled: "(結算前請先移除已失效商品)",
    removeAllDisabled: "移除所有已失效商品",
  },
  checkout: {
    metaTitle: "Kingsmart | 结算",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    checkout: "结算",
    billingDetails: "帐单详情",
    firstName: "名字",
    lastName: "姓氏",
    phone: "电话",
    email: "电邮地址",
    companyName: "公司名称",
    addressLine1: "街道地址",
    addressLine2: "街道地址 2",
    addressLine3: "城市",
    stateProvince: "州 / 省",
    zipCode: "邮递编号",
    countryRegion: "国家 / 地区",
    additionalInfo: "额外资讯",
    otherRemarks: "其他备注",
    yourOrder: "您的订单",
    product: "产品",
    total: "需支付金額",
    delivery: "送货",
    freeShipping: "免费送货",
    submitOrder: "提交订单",
    discount: "折扣",
    totalDiscount: "总折扣",
    noItemInCart: "购物车内没有货物",
    buyNow: "立即选购",
    noStock: "商品没有库存",
    confirmBooking: "請留意電郵通知，職員將盡快確認您的預約",
    insufficientCoin:
      "您的帳戶未有足夠的K coins結餘。如欲繼續以信用卡付款，請按「是」。",
    staffCode: "輸入職員編號",
    apply: "應用",
    tokenError: "二維碼時效已過或已被使用",
    required: "必填",
    optional: "選填",
    redemption: "積分兌換",
    remove: "移除",
    failedToCheckout: "無法為此帳單結算。請再次檢查購物車。",
    wrongStaffCode: "職員編號無效",
    insufficientPoint: "積分不足",
    district: "區域",
    area: "地區",
    locker: "自提點",
    lockerReminder: "如貨品總重量大於5kg，將有人聯絡您安排點到點送貨",
    lockerAddress: "地址",
    lockerOpenHours: "開放時間",
    flat: "單位/室",
    floor: "樓層",
    block: "座數",
    building: "大廈",
    street: "街道",
  },
  paymentNotification: {
    metaTitle: "Kingsmart | 付款结果",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    paymentNotification: "付款结果",
    backToHome: "返回主页",
    orderSuccessTitle: "成功下单",
    orderSuccessMsg:
      "感谢您的购买！我们已收到您的订单，请检查您的电子邮件以获取更多信息。",
    orderCancelledTitle: "订单取消",
    orderCancelledMsg: "您的订单已被取消，请联系客户服务以获取更多信息。",
    orderRejected: "交易被拒绝，请联系你的发卡银行以获取更多信息。",
    orderPendingTitle: "待处理订单",
    orderPendingMsg:
      "感谢您的购买！我们已收到您的订单，请检查您的电子邮件以获取更多信息。",
  },
  aboutUs: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    aboutUs: "关于我们",
  },
  blog: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    relatedProducts: "相关产品",
    relatedCategories: "相关产品种类",
    blogPost: "Blog Post",
  },
  wishlist: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    wishlist: "收藏",
    image: "图片",
    productName: "产品名字",
    unitPrice: "单价",
    selectOptions: "选择款式",
    addToCart: "新增至购物车",
    addedToCart: "已加到购物车",
    outOfStock: "即將推出",
    continueShopping: "继续购物",
  },
  compare: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    compare: "比较",
    product: "产品",
    unitPrice: "单价",
    description: "产品描述",
    sku: "款式",
    option: "选项",
    additionalInfo: "更多资料",
    view: "检视",
    continueShopping: "继续购物",
  },
  resetPassword: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    resetPassword: "重置密码",
    newPassword: "新密码",
    confirmPassword: "确认新密码",
  },
  alerts: {
    accountNotFound: "帐户不存在",
    wrongEmail: "请提供正确的电邮地址",
    wrongConfirmPassword: "新密码与确认密码不符",
    wrongPasswordFormat:
      "请提供正确的密码，密码需至少包含8个字符，以数字及英文字母组成 (包括1个大阶字母)",
    wrongPhoneFormat: "请提供正确的手提号码",
    fillNecessaryInfo: "请填写全部资料",
    updateAccountInfoSuccess: "资料已被更改",
    updatePasswordSuccess: "密码已被更改",
    referencePriceRemarks: "参考价钱",
  },
  qrCodeScanner: {
    scan: "掃描二維碼",
    enableAccess: "請檢查相機權限",
    or: "或",
    inputMobile: "輸入客戶電話號碼",
    submit: "提交",
  },
  customerRedemption: {
    metaTitle: "Kingsmart | 商品兌換",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "預約服務",
    image: "相片",
    productDetails: "商品詳情",
    unitPrice: "單價",
    staffCode: "職員編號",
    redeem: "兌換",
    invalidCode: "二維碼無效",
    enterMobile: "請輸入客戶電話號碼",
    redeemed: "已兌換",
    enterCode: "請輸入職員編號",
    redeemedAt: "換領日期",
    expiredAt: "到期日",
    cancelledAt: "取消日期",
    available: "可兌換",
    expired: "已逾期",
    cancelled: "已取消",
    noResults: "沒有結果",
  },
  coupon: {
    active: "有效",
    event: "活動",
    code: "優惠碼",
    purchase_reached: "需消費滿",
    expiry_date: "有效日期",
    redeemed_expired: "已換領 / 已過期",
  },
  simplr: {
    unavailable: "所選時段未能預約，請選擇另一時段",
  },
};
