import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "me";

const MeActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_ME: `${RESOURCE}/GET_ME`,
  GET_COUPONS: `${RESOURCE}/GET_COUPONS`,
  GET_ORDERS: `${RESOURCE}/GET_ORDERS`,
  GET_PURCHASED_ITEMS: `${RESOURCE}/GET_PURCHASED_ITEMS`,
  GET_WALLET: `${RESOURCE}/GET_WALLET`,
  GET_WALLET_HISTORY: `${RESOURCE}/GET_WALLET_HISTORY`,
  GET_REWARD: `${RESOURCE}/GET_REWARD`,
  GET_REWARD_HISTORY: `${RESOURCE}/GET_REWARD_HISTORY`,
  CLAIM_DAILY: `${RESOURCE}/CLAIM_DAILY`,
  CLAIM_DAILY_RESET: `${RESOURCE}/CLAIM_DAILY_RESET`,
};

export default MeActionTypes;
