import Immutable from "seamless-immutable";
import ProductActionTypes from "./actionTypes";

const initialState = Immutable({});

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductActionTypes.CREATE:
      return state;
    default:
      return state;
  }
};

export default ProductReducer;
