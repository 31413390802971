import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import CurrencyActions from "./currency/action";
import FloneProductActions from "./flone/product/actions";
import ContentActions from "./content/action";
import ProductActions from "./product/action";
import BlogActions from "./blog/action";
import HashTagActions from "./hashtag/action";
import CategoryActions from "./category/action";
import UserActions from "./user/action";
import WishlistActions from "./wishlist/action";
import CartActions from "./cart/action";
import MeActions from "./me/action";
import DiscountActions from "./discount/action";
import OrderActions from "./order/action";
import CompareActions from "./compare/action";
import StoreConfigActions from "./storeConfig/action";
import PaymentMethodActions from "./paymentMethod/action";
import ShippingMethodActions from "./shippingMethod/action";
import PostActions from "./post/action";
import MerchantActions from "./merchant/action";
import PurchasedItemActions from "./purchasedItem/action";
import TokenActions from "./token/action";
import RewardActions from "./reward/action";
import DeliveryProviderActions from "./deliveryProvider/action";

export const ActionCreators = {
  CartActions,
  CompareActions,
  CurrencyActions,
  FloneProductActions,
  WishlistActions,
  ContentActions,
  ProductActions,
  BlogActions,
  HashTagActions,
  CategoryActions,
  UserActions,
  MeActions,
  DiscountActions,
  OrderActions,
  StoreConfigActions,
  PaymentMethodActions,
  ShippingMethodActions,
  PostActions,
  MerchantActions,
  PurchasedItemActions,
  TokenActions,
  RewardActions,
  DeliveryProviderActions,
};

export function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

export default ActionCreators;
