import React from "react";

export default {
  loading: "Loading",
  homepage: "Homepage",
  search: "Search",
  searchHistory: "Search History",
  default: "Default",
  save: "Save",
  copy: "Copy",
  scroll_down: "Scroll Down",
  noRecord: "No Records",
  add: "Add",
  date: "Date",
  delete: "Delete",
  quit: "Quit",
  apply: "Apply",
  navMenu: {
    login: "Login",
    register: "Register",
    myAccount: "My Account",
    logout: "Log out",
    quantity: "Quantity",
    sku: "Model",
    option: "Option",
    total: "Total",
    viewCart: "View Cart",
    checkout: "Checkout",
    noItemInCart: "No item in cart",
    aboutUs: "About Us",
    redemption: "Customer Redemption",
    booking: "Booking Confirmation",
    balance: "Coins Balance: {{coins}}",
    sessionExpired: "Your session has expired. Please login again.",
    invalidQrCode: "QR Code invalid. Please try again.",
    scanError: "Failed to scan QR Code. Please try again.",
    popupError:
      "Pop-ups are blocked by your browser. Please check your browser setting.",
  },
  footer: {
    aboutus: "About Us",
    contact: "contact",
    usefulLinks: "Useful Links",
    disclaimer: "Disclaimer",
    policy: "Delivery & Returns Policy",
    tnc: "Terms and Conditions",
    guide: "Shopping Guide",
    subscribeUs: "Subscribe Us",
    Facebook: "Facebook",
    Youtube: "Youtube",
    Instagram: "Instagram",
  },
  home: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    buyNow: "Buy Now",
    latestProducts: "New Arrivals",
    latestProductsSubTitle: "Provide you the latest products each month",
    new: "New",
    selectOptions: "Select Options",
    addToCart: "Add to Cart",
    addedToCart: "Added to Cart",
    outOfStock: "Coming Soon",
    view: "View",
    addedToWishlist: "Added to Wishlist",
    addToWishlist: "Add to Wishlist",
    blogTitle: "Latest News",
    writtenBy: "Written by",
    featureTitle1: "2 FREE SAMPLES",
    featureSubTitle1: "With Every Order",
    featureTitle2: "FREE NEXT DAY DELIVERY",
    featureSubTitle2: "Order by 12 pm. HK Only.",
    featureTitle3: "FREE GIFT WRAP",
    featureSubTitle3: "Select At Checkout",
    bannerTitle1: "",
    bannerTitle2: "",
    viewMoreProducts: "View more products",
    moreBlogs: "View more blogs",
  },
  productList: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    allProducts: "All Products",
    priceLowToHigh: "Price - Low to High",
    priceHighToLow: "Price - High to Low",
    updateTimeNewToOld: "Update Time - New to Old",
    updateTimeOldToNew: "Update Time - Old to New",
    categories: "Categories",
    noCategories: "All Categories",
    buyNow: "Buy Now",
    new: "New",
    selectOptions: "Select Options",
    addToCart: "Add to Cart",
    addedToCart: "Added to Cart",
    outOfStock: "Coming Soon",
    view: "View",
    addedToWishlist: "Added to Wishlist",
    addToWishlist: "Add to Wishlist",
    addedToCompare: "Added to Compare",
    addToCompare: "Add to Compare",
    numOfResult: "Showing {{current}} of {{total}} results",
    newArrivals: "New Arrivals",
    brands: "Brands",
    noBrands: "All Brands",
    point: "points",
    tierRestriction: "Restricted to specific customer tier(s)",
  },
  productDetails: {
    metaTitle: "Kingsmart | Product Detail",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    productDetails: "Product Details",
    sku: "Model",
    option: "Option",
    buyNow: "Buy Now",
    addToCart: "Add to Cart",
    outOfStock: "Coming Soon",
    additionalInfo: "Additional information",
    alsoBuy: "You may also like",
    quantity: "Quantity",
    packageTotal: "Package price",
    relatedProducts: "Related Product",
    alsoLike: "You may also like",
    relatedCategories: "Related Categories",
    categories: "Categories",
    packageDetails: "Package Details",
    preferredDate: "Preferred Date",
    preferredTime: "Preferred Time",
    remark: "Remark",
    select: "Select",
    simplrStart: "Start Time",
    simplrEnd: "End Time",
    simplrInvoice: "Booking Reference",
    point: "points",
    fullName: "Full Name",
  },
  loginRegister: {
    metaTitle: "Kingsmart | Login / Register",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    loginRegister: "Login｜Register",
    login: "Login",
    register: "Register",
    email: "Email",
    password: "Password",
    rememberMe: "Remember me",
    forgotPassword: "Forgot Password",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    loginPanelTitle: "Already a member?",
    registerPanelTitle: "Join now!",
    registerPanelSubTitle:
      "Register to become a member. Start tuned of our latest news!",
    registerModalTitle: "Member Register",
    agreeToSubscribe: "I agree to subscribe to the newsletter",
    resetPasswordMsg:
      "We will send an email to your email address for reset password if your record is found in our system.",
    validateAccountMsg: "Email validation success.",
    error_contact_service: "Please contact customer service.",
    error_email_password: "Email or password does not match.",
    error_account_disabled: "Your account has been disabled.",
    referralCode: "Referral Code",
    gender: "Gender",
    birth: "Birthday Date",
    male: "Male",
    female: "Female",
    undisclosed: "Undisclosed",
    socialLogin: "Use your social account to login",
    socialRegister: "Use your social account to register",
    oAuthNoEmailError:
      "Email is empty. Please check you social account profile or fill in the registration form.",
    guestCheckout: "Guest Checkout",
  },
  myAccount: {
    metaTitle: "Kingsmart | My Account",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    myAccount: "My Account",
    personalInfo: "Personal",
    deliveryAddress: "Billing/Shipment address",
    orderRecord: "Order Records",
    personalInfoTitle: "Account Information",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    email: "Email",
    referral_code: "Account Code",
    address: "Address",
    changePassword: "Change Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    addNewAddress: "Add New Delivery Address",
    addressLine1: "Street Address",
    addressLine2: "Street Address 2",
    addressLine3: "City",
    stateProvince: "State / Province",
    zipCode: "Zip Code",
    countryRegion: "Country / Region",
    order: "Order",
    orderId: "Order ID",
    orderStatus: "Order Status",
    paymentStatus: "Payment Status",
    deliveryStatus: "Delivery Status",
    total: "Total",
    details: "Details",
    receiverName: "Receiver Name",
    contactPhone: "Contact Number",
    image: "Image",
    productName: "Product Name",
    unitPrice: "Unit Price",
    quantity: "Quantity",
    subTotal: "Sub Total",
    sku: "Model",
    option: "Option",
    membership: "Membership",
    coupons: "Coupons",
    membershipTier: "Membership Tier",
    amountSpent: "You have spent {{amount}} within 12 months",
    credit: "Credit",
    totalCredit: "Total Credit",
    expiryDate: "Expiry Date",
    dollars: "{{amount}} Coins",
    date: "Date",
    event: "Event",
    silver: "Silver",
    gold: "Gold",
    discountName: "Discount Name",
    discountAmount: "Discount Amount",
    productDetails: "Product Details",
    discountDetails: "Discount Details",
    shippingFee: "Shipping Fee",
    preferredDate: "Booking Date",
    preferredTime: "Booking Time",
    remark: "Remark",
    status: "Status",
    redemption: "Redemption",
    noResults: "No Results",
    adjust: "Adjust",
    pay: "Pay",
    topup: "Top-up",
    rebate: "Rebate",
    reward: "Reward",
    totalPoint: "Total Point",
    points: "Points",
    welcome: "Welcome",
    firstPurchase: "First Purchase",
    dailyLogin: "Daily Login",
    referral: "Referral",
    convert: "Convert",
    convertToKcoin: "Convert to Coins",
    youHave: "You have",
    exchangeTo: "You can exchange to",
    purchasedContent: "View Purchased Content",
    shippingMethod: "Shipping Method",
    trackingNumber: "Tracking Number",
    pendingCreate: "Pending",
    shipped: "Shipped",
    inTransit: "In transit",
    completed: "Completed",
    error: "Error",
    na: "N/A",
    processing: "Processing",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
    paid: "Paid",
    notPaid: "Not Paid",
  },
  cart: {
    metaTitle: "Kingsmart | Cart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    cart: "Cart",
    yourCart: "Your Cart",
    image: "Image",
    productName: "Product Name",
    unitPrice: "Unit Price",
    quantity: "Quantity",
    subTotal: "Sub Total",
    sku: "Model",
    option: "Option",
    continueShopping: "Continue Shopping",
    clearCart: "Clear Cart",
    useCoupon: "Use Coupon Code",
    inputCouponCode: "Input Coupon Code",
    applyCouponCode: "Apply Coupon Code",
    cartTotal: "Cart Total",
    totalDiscount: "Total Discount",
    total: "Grand Total",
    continueCheckout: "Checkout",
    noItemInCart: "No Item in your cart",
    buyNow: "Buy Now",
    decremented: "Item Decremented From Cart",
    removed: "Removed From Cart",
    removedAll: "Removed All From Cart",
    noStock: "Item out of stock",
    linkAccount: "Link up Customer's Account",
    checkout: "Checkout Now",
    disabledItems: "Invalid Items",
    removeDisabled: "(Please remove the below items before checkout)",
    removeAllDisabled: "Remove all invalid items from cart",
    tierRestriction:
      "Cart contains invalid items for your tier. All invalid items will be removed from cart.",
  },
  checkout: {
    metaTitle: "Kingsmart | Checkout",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    checkout: "Checkout",
    billingDetails: "Billing Details",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    email: "Email",
    companyName: "Company Name",
    addressLine1: "Street Address",
    addressLine2: "Street Address 2",
    addressLine3: "City",
    stateProvince: "State / Province",
    zipCode: "Zip Code",
    countryRegion: "Country / Region",
    additionalInfo: "Additional Information",
    otherRemarks: "Other Remarks",
    yourOrder: "Your Order",
    product: "Product",
    total: "Amount Payable",
    delivery: "Delivery",
    discount: "Discount",
    totalDiscount: "Total Discount",
    freeShipping: "Free Shipping",
    submitOrder: "Submit Order",
    noItemInCart: "No item in your cart",
    buyNow: "Buy Now",
    missingInfo: "Please fill in missing information.",
    error: "System Error, please try again.",
    invalidCode: "Invalid Discount Code.",
    paymentDetails: "Payment Details",
    paymentMethod: "Payment Method",
    shippingDetails: "Shipping Details",
    shippingMethod: "Shipping Method",
    deliveryDetails: "Delivery Details",
    sameAddress: "Billing address is the same as delivery address",
    confirmBooking:
      "Staff will confirm your booking very soon. Updates will be sent to your mailbox.",
    insufficientCoin:
      'There is insufficient K coins in your account. If you wish to checkout by credit card, please click "Yes".',
    staffCode: "Input Staff Code",
    apply: "Apply",
    tokenError: "QR Code has been expired or revoked.",
    required: "Required",
    optional: "Optional",
    redemption: "Redeem Credits",
    remove: "Remove",
    failedToCheckout:
      "Unable to checkout this order. Please review your cart items.",
    wrongStaffCode: "Please input correct staff code",
    insufficientPoint: "Insufficient Point",
    district: "District",
    area: "Area",
    locker: "Pick Up Point",
    lockerReminder:
      "If total weight of the products is >5kg, we will contact you separately to arrange delivery",
    lockerAddress: "Address",
    lockerOpenHours: "Open Hours",
    flat: "Flat/Room",
    floor: "Floor",
    block: "Block",
    building: "Building",
    street: "Street",
    remark: "Remark",
    tierRestriction:
      "Order contains items that are not available for your tier. Please review your cart items.",
  },
  paymentNotification: {
    metaTitle: "Kingsmart | Payment Result",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    transactionId: "Transaction ID",
    paymentNotification: "Payment Result",
    backToHome: "Back to Homepage",
    orderSuccessTitle: "Order Success",
    orderSuccessMsg:
      "Thanks for purchasing! We have received your order and please check your email for further information.",
    orderCancelledTitle: "Order Cancelled",
    orderCancelledMsg:
      "Your order may be cancelled. Please contact our customer services for further information.",
    orderRejected:
      "Transaction rejected, please contact your card issuing bank.",
    orderPendingTitle: "Order Pending",
    orderPendingMsg:
      "Thanks for purchasing! We have received your order and please check your email for further information.",
  },
  aboutUs: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    aboutUs: "About Us",
  },
  blog: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    relatedProducts: "Related Products",
    relatedCategories: "Related Categories",
    blogPost: "NEWS",
  },
  blogs: {
    title: "News",
  },
  wishlist: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    wishlist: "Wishlist",
    image: "Image",
    productName: "Product Name",
    unitPrice: "Unit Price",
    selectOptions: "Select Options",
    addToCart: "Add to Cart",
    addedToCart: "Added to Cart",
    outOfStock: "Coming Soon",
    continueShopping: "Continue Shopping",
    buyNow: "Buy Now",
    added: "Added to Wishlist",
    removed: "Removed From Wishlist",
  },
  compare: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    compare: "Compare",
    product: "Product",
    unitPrice: "Unit Price",
    description: "Description",
    sku: "Model",
    option: "Option",
    additionalInfo: "Additional information",
    view: "View",
    continueShopping: "Continue Shopping",
  },
  resetPassword: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    resetPassword: "Reset Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
  },
  alerts: {
    accountNotFound: "Account not found",
    wrongEmail: "Please provide correct email",
    wrongConfirmPassword: "New and confirm password are different",
    wrongPasswordFormat:
      "Please fill in correct password.\n" +
      "Password consists of at least 8 characters, which must include number and letters (at least one capital letter)",
    wrongPhoneFormat: "Please provide correct mobile",
    fillNecessaryInfo: "Please fill in all necessary information",
    updateAccountInfoSuccess: "Information has been updated",
    updatePasswordSuccess: "Password has been updated",
    referencePriceRemarks: "Reference price",
    loginFirst: "Please login first",
  },
  qrCodeScanner: {
    scan: "Scan QR Code",
    enableAccess: "Please Enable Camera Access",
    or: "or",
    inputMobile: "Input customer's mobile",
    submit: "Submit",
  },
  customerRedemption: {
    metaTitle: "Kingsmart | Customer Redemption",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "Booking Items",
    image: "Image",
    productDetails: "Product Details",
    unitPrice: "Unit Price",
    staffCode: "Staff Code",
    redeem: "Redeem",
    invalidCode: "Invalid QR Code",
    enterMobile: "Please enter customer mobile",
    redeemed: "Redeemed",
    enterCode: "Please enter staff code",
    redeemedAt: "Redemption Date",
    expiredAt: "Expiry Date",
    cancelledAt: "Cancellation Date",
    available: "Available",
    expired: "Expired",
    cancelled: "Cancelled",
    noResults: "No Results",
  },
  bookingConfirmation: {
    metaTitle: "Kingsmart | Booking Confirmation",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "Booking Items",
    transactionId: "Transaction ID",
    orderId: "Order Number",
    image: "Image",
    productDetails: "Product Details",
    unitPrice: "Unit Price",
    staffCode: "Staff Code",
    redeem: "Redeem",
    redeemed: "Redeemed",
    enterCode: "Please enter staff code",
    preferredDate: "Preferred Date",
    preferredTime: "Preferred Time",
    remark: "Remark",
    submit: "Submit",
    book_success: "Service has been updated",
    pending: "Pending",
    revoked: "Revoked",
    expired: "Expired",
    please_login_with_merchant_admin:
      "Please login with merchant admin account",
  },
  coupon: {
    active: "Active",
    event: "Event",
    code: "Code",
    purchase_reached: "Purchase Reached",
    expiry_date: "Expiry Date",
    redeemed_expired: "Redeemed / Expired",
  },
  simplr: {
    unavailable: "Period unavailable. Please try another timeslot",
  },
};
