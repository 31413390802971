import Immutable from "seamless-immutable";
import HashTagActionTypes from "./actionTypes";

const initialState = Immutable({});

const HashTagReducer = (state = initialState, action) => {
  switch (action.type) {
    case HashTagActionTypes.CREATE:
      return state;
    default:
      return state;
  }
};

export default HashTagReducer;
