import Immutable from "seamless-immutable";
import StoreConfigActionTypes from "./actionTypes";

const initialState = Immutable({
  exchangeRate: {
    HKD: 1,
  },
  siteTitle: {
    en: "PinNBuy",
  },
  isMaintenance: false,
  logo: {
    fileName: "",
    fileUrl: "",
  },
  disclaimer: "",
  policy: "",
  tnc: "",
  guide: "",
  banner: [],
  usefulLink: [],
  socialLink: [],
  aboutUsLink: [],
  contact: "",
  locale: {
    default: "en",
    support: ["en", "zh"],
  },
  currency: {
    primary: "HKD",
    support: ["HKD"],
  },
  homeCategories: [],
});

const StoreConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoreConfigActionTypes.GET_LIST:
      return { ...state, ...action.data.message.record };
    default:
      return state;
  }
};

export default StoreConfigReducer;
